<template>
    <div class="p-2">
        <div class="d-flex align-items-center mb-2">
            <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
            <div class="flex-wrap">

            </div>
        </div>
        <div class="px-4 py-2">
            <div class="row">
                <div class="col-9">
                    <h4>Your invoices</h4>
                    <paginated-result-loader endpoint="billing/invoices" cell-type="invoices"
                                             :filters="request"></paginated-result-loader>
                </div>
                <div class="col-3 pr-2">
                    <h4>Since last bill</h4>
                    <b-card class="text-center">
                        <div v-if="loadingUnbilled">
                            <b-spinner variant="primary" small type="grow"></b-spinner>
                        </div>
                        <div v-else>
                            <div class="display-4">
                                £{{ unbilled.cost.toFixed(2) }}
                            </div>
                            <div class="text-muted">
                                <b-badge variant="success m-1">
                                    x{{ unbilled.applications }} applications
                                </b-badge>
                                <b-badge variant="info m-1">
                                    x{{ unbilled.doc_checks }} document checks
                                </b-badge>
                            </div>

                            <div class="text-muted mt-3 font-weight-bold" style="font-size:14px;">
                                Next invoice date : {{ unbilled.billing_date != null ? dateToNice(unbilled.billing_date) : "n/a" }}
                            </div>
                            <div v-if="unbilled.limit != null && unbilled.billing_date != null" class="text-muted mt-0 font-weight-bold" style="font-size:14px;">
                                {{ "Or when balance reaches " + unbilled.limit }}
                            </div>
                        </div>
                    </b-card>
                </div>

            </div>
        </div>
        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false">
                    <b-icon-x-circle></b-icon-x-circle>
                </a>
            </div>
            <router-view></router-view>
        </b-modal>
    </div>
</template>

<script>
    import FiltersLoader from "../../../components/FiltersLoader";
    import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
    import _ from 'lodash'
    import {unbilled} from "../../../modules/api/endpoints";
    import {getResource} from "../../../modules/api/methods";
    import moment from "moment";

    export default {
        name: "transactions",
        components: {PaginatedResultLoader, FiltersLoader},
        data: function () {
            return {
                showModal: false,
                request: {},
                loadingUnbilled: false,
                unbilled: {},
                paginatedResponse: {},
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Invoices',
                        active: true
                    }
                ]
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    if (newVal.params.data_updated !== undefined) {
                        this.request = _.clone(this.request)
                    }
                }
            },
            showModal: function (newVal, oldVal) {
                if (!newVal) {
                    if (this.$router.currentRoute.name !== 'transactions') {
                        this.$router.replace({'name': 'transactions', params: {'data_updated': true}}).catch((err) => {});
                    }
                }
                if(newVal !== oldVal && !newVal) {
                    this.request = _.clone(this.request)
                }
            }
        },
        created() {
            this.loadUnbilled();
        },
        methods: {
            dateToNice(date) {
                return new moment(date).format("DD/MM/YYYY");
            },
            filtersUpdated(filters) {
                this.request = filters;
            },
            loadUnbilled() {
                this.loadingUnbilled = true;
                getResource(unbilled).then((resp) => {
                    console.log(resp);
                    this.unbilled = resp.data.success;
                }).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    this.loadingUnbilled = false;
                });
            }
        }

    }
</script>
